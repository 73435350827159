<template>
  <div class="MBTI">
    <div class="textIndex">
      <h1 class="titleC">MBTI性格测试</h1>
      <div  class="secodeTitle" v-if="isShow">
        本测试共分四个部分，请在二十分钟内完成测试。
        <br>
        不要花太多时间思考任何一题。
      </div>
       <div v-else>
          <div  class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults}}</span></div>
      </div>
    </div>
    <!-- 测试题div -->
    <div class="opdiv" v-if="isShow">
      <!-- 题量 -->
      <div class="opdivSon">
        <span class="span1">{{ showIndex + 1 }}</span>
        <span class="span2">/</span>
        <span class="span3">{{ list.length }}</span>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div v-show="showIndex == index">
          <div class="timu" style="top: 17.5%;">{{ item[0].title }}</div> <!--//题目-->
          <span class="timu" style="top: 28%;font-size: 14px;">{{ item[0].content }}</span>
          <div class="daan">
            <div class="daanItem" style="margin-top: 80px;" v-for="(questionItem, questionIndex) in item"
              :key="questionItem.id" @click="ophanclick(item, index, questionItem)"
              :class="{ 'bgc': questionItem.clicked }">
              {{ questionItem.optionsContent }}
              <img v-if="questionItem.clicked" src="@/assets/imgs/@2x.png" alt="" class="daanItemimg">
            </div>
          </div>
          <!-- 下一题按钮 -->
          <div class="btns">
            <div class="btn1" @click="changeType(item, index, 'prev')">上一题</div>
            <div class="btn2" @click="changeType(item, index, 'next')">下一题</div>
            <div class="btn3" @click="submit" v-if="list.length - 1 == index">提交</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 测试结果 -->
    <div class="opdiv opdivResult"  v-else>
      <!-- <div class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults }}</span></div> -->
      <div style="text-align: center;margin-top: 10px">
        {{ mbtiResult.soul }}
      </div>
      <div class="ql-container ql-snow">
        <div class="ql-editor" style="line-height: 30px;" v-html="mbtiResult.results">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryTest2, queryTest2Anwser } from '@/api/homeIndex.js'

export default {
  name: 'MBTI',
  mixins: [],
  components: {},
  props: [],
  data() {
    return {
      list: [],
      showIndex: 0,
      isShow: true,
      mbtiResult: {},
      correspondingResults:''
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.getList()

  },
  methods: {
    ophanclick(item, index, questionItem) {

      item.forEach(aaa => {
        aaa.clicked = false
      })
      questionItem.clicked = !questionItem.clicked
      setTimeout(() => {
        this.changeType(item, index, 'next')

      }, 100);
    },
    //提交结果
    submit() {
      let answerList = []
      this.list.forEach(item => {
        item.forEach(questionItem => {
          if (questionItem.clicked) {
            answerList.push(questionItem.correspondingResults)
          }
        })
      })
      let E = answerList.filter(item => item == 'E').length;
      let S = answerList.filter(item => item == 'S').length;
      let T = answerList.filter(item => item == 'T').length;
      let J = answerList.filter(item => item == 'J').length;
      let I = answerList.filter(item => item == 'I').length;
      let N = answerList.filter(item => item == 'N').length;
      let F = answerList.filter(item => item == 'F').length;
      let P = answerList.filter(item => item == 'P').length;

      let strlist = ''
      E > I ? strlist += 'E' : strlist += 'I'
      S > N ? strlist += 'S' : strlist += 'N'
      T > F ? strlist += 'T' : strlist += 'F'
      J > P ? strlist += 'J' : strlist += 'P'
      console.log(strlist);


      if (strlist) {
        queryTest2Anwser({ correspondingResults: strlist }).then(res => {
          this.mbtiResult = res.data[0]
          this.isShow = false
          this.correspondingResults = strlist
        })
      }


    },
    // 上一题 下一题
    changeType(item, index, type) {

      if (type == 'prev') {   //上一题
        if (index == 0) return   //如果是第一题，则return
        this.showIndex = index - 1
      } else {    //下一题
        if (!item.some(questionItem => questionItem.clicked)) {
          this.$message.warning('请选择一个选项')
          return
        }
        if (index == this.list.length - 1) return    //如果是最后一题，则return
        this.showIndex += 1
      }
    },
    getList() {

      queryTest2().then(res => {

        res.rows.forEach(item => {
          item.forEach(questionItem => {
            questionItem.clicked = false
          })
        })
        this.list = res.rows

      })

    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>

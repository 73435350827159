<template>
  <div style="padding-bottom: 20px;">
    <!-- 霍兰德 -->
    <div v-if="type == '1'">
      <HLD></HLD>
    </div>
    <!-- MBTI -->
    <div v-if="type == '2'">
      <MBTI></MBTI>
    </div>
    <!-- DISC -->
    <div v-if="type == '3'">
      <DISC></DISC>
    </div>

  </div>
</template>

<script>
import DISC from './DISC.vue'
import HLD from './HLD.vue'
import MBTI from './MBTI.vue'
export default {
  name: 'tketest',
  data() {
    return {
      type:undefined
    };
  },
  components: {
    DISC,
    MBTI,
    HLD
  },
  methods: {

  },
  mounted() {
    this.type = this.$route.query.type;
        window.scrollTo({
              top: 0,
              behavior: 'smooth' // 平滑滚动
          });
  }

}


</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="DISC">
    <div class="textIndex">
      <h1 class="titleC">DISC性格测试</h1>
      <div  class="secodeTitle"  v-if="isShow">
        在每一个大标题中的四个选择题中只选择一个最符合你自己的，并在英文字母后面做记号。一共40题。不能遗漏。<br>
        <span>注意：请按第一印象最快的选择，如果不能确定，可回忆童年时的情况，或者以你最熟悉的人对你的评价来从中选择。</span>
      </div>
       <div v-else>
          <div  class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults}}</span></div>
      </div>
    </div>
    <!-- 测试题div -->
    <div class="opdiv" v-if="isShow">
      <!-- 题量 -->
      <div class="opdivSon">
        <span class="span1">{{ showIndex + 1 }}</span>
        <span class="span2">/</span>
        <span class="span3">{{ list.length }}</span>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div v-show="showIndex == index">
          <div class="timu">{{ item.timu }}</div> <!--//题目-->
          <div class="daan">
            <div class="daanItem" v-for="(questionItem, questionIndex) in item" :key="questionItem.id"
              @click="ophanclick(item, index, questionItem)" :class="{ 'bgc': questionItem.clicked }">
              {{ questionItem.content }}
              <img v-if="questionItem.clicked" src="@/assets/imgs/@2x.png" alt="" class="daanItemimg">
            </div>
          </div>
          <!-- 下一题按钮 -->
          <div class="btns">
            <div class="btn1" @click="changeType(item, index, 'prev')">上一题</div>
            <div class="btn2" @click="changeType(item, index, 'next')">下一题</div>
            <div class="btn3" @click="submit" v-if="list.length - 1 == index">提交</div>

          </div>
        </div>

      </div>
    </div>
    <!-- 测试结果 -->
    <div class="opdiv opdivResult"   v-else>
      <!-- <div class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults }}</span></div> -->
      <div style="padding-top: 2%; box-sizing: border-box;" v-for="(item, index) in resultList"
        :key="index">
        <div style="font-size: 18px;text-align: center;">
          {{ item.title }}
        </div>
        <div class="itemresult">{{ item.resOne }}</div>
        <div class="itemresult">{{ item.resTwo }}</div>
        <div class="itemresult">{{ item.resThree }}</div>
        <div class="itemresult">{{ item.resFour }}</div>
        <div class="itemresult">{{ item.partTwoa }}</div>
        <div class="itemresult">{{ item.partTwob }}</div>
        <div class="itemresult">{{ item.partTwoc }}</div>
        <div class="itemresult">{{ item.partThreeTrue }}</div>
        <div class="itemresult">{{ item.partThreeFalse }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryTest3, queryTest3Anwser } from '@/api/homeIndex.js'

export default {
  name: 'DISC',
  mixins: [],
  components: {},
  props: [],
  data () {
    return {
       list: [],
      showIndex: 0,
      isShow: true,
      resultList: [],
      correspondingResults:''
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
      this.getList()
  },
  methods: {
        ophanclick(item, index, questionItem) {
      item.forEach(aaa => {
        aaa.clicked = false
      })
      questionItem.clicked = !questionItem.clicked
      setTimeout(() => {
        this.changeType(item, index, 'next')

      }, 100);
    },
    //提交结果
    submit() {



      let answerList = []
      this.list.forEach(item => {
        item.forEach(questionItem => {
          if (questionItem.clicked) {
            answerList.push(questionItem.correspondingResults)
          }
        })
      })

      let DNum = answerList.filter(item => item == 'D').length;
      let INum = answerList.filter(item => item == 'I').length;
      let SNum = answerList.filter(item => item == 'S').length;
      let CNum = answerList.filter(item => item == 'C').length;
      let correspondingResults = ''
      DNum >= 10 ? correspondingResults = 'D' : ''
      INum >= 10 ? correspondingResults = correspondingResults + '|' + 'I' : correspondingResults
      SNum >= 10 ? correspondingResults = correspondingResults + '|' + 'S' : correspondingResults
      CNum >= 10 ? correspondingResults = correspondingResults + '|' + 'C' : correspondingResults
      console.log(DNum,INum,SNum,CNum)
      if (correspondingResults.substr(0, 1) == '|') {
        correspondingResults = correspondingResults.substr(1)
      }
      if (correspondingResults.substr(correspondingResults.length - 1, 1) == '|') {
        correspondingResults = correspondingResults.substr(0, correspondingResults.length - 1)
      }
      console.log(correspondingResults)
      queryTest3Anwser({ correspondingResults }).then(res => {
        this.isShow = false
        this.resultList = res.data
        this.correspondingResults = correspondingResults
      })

    },
    // 上一题 下一题
    changeType(item, index, type) {
      if (type == 'prev') {   //上一题
        if (index == 0) return   //如果是第一题，则return
        this.showIndex = index - 1
      } else {    //下一题
        if (!item.some(questionItem => questionItem.clicked)) {
          this.$message.warning('请选择一个选项')
          return
        }
        if (index == this.list.length - 1) return    //如果是最后一题，则return
        this.showIndex += 1
      }
    },
    getList() {

              queryTest3().then(res => {
                res.rows.forEach(item => {
                  item.forEach(questionItem => {
                    questionItem.clicked = false
                  })
                })
                this.list = res.rows
              })

    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>

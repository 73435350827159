<template>
  <div class="HLD">
    <div class="textIndex">
      <h1 class="titleC">霍兰德职业测试</h1>
      <div class="secodeTitle" v-if="isShow">
        请根据对每一题目的第一印象作答，不必仔细推敲，答案没有好坏、对错之分。一共60题。
        <br>
        具体填写方法是，根据自己的情况回答“是” 或"否"
      </div>
      <div v-else>
          <div  class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults}}</span></div>
      </div>
    </div>
    <!-- 测试题div -->
    <div class="opdiv" v-if="isShow">
      <!-- 题量 -->
      <div class="opdivSon">
        <span class="span1">{{ showIndex + 1 }}</span>
        <span class="span2">/</span>
        <span class="span3">{{ list.length }}</span>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div v-show="showIndex == index">
          <div class="timu" style="top:16%;padding-left: 10px;">{{ item[0].content }}</div> <!--//题目-->
          <div class="daan" style="padding-top:12%; ">
            <div class="daanItem" v-for="(questionItem, questionIndex) in item" :key="questionItem.id"
              @click="ophanclick(item, index, questionItem)" :class="{ 'bgc': questionItem.clicked }">
              {{ questionItem.options }}
              <img v-if="questionItem.clicked" src="@/assets/imgs/@2x.png" alt="" class="daanItemimg">
            </div>
          </div>
          <!-- 下一题按钮 -->
          <div class="btns">
            <div class="btn1" @click="changeType(item, index, 'prev')">上一题</div>
            <div class="btn2" @click="changeType(item, index, 'next')">下一题</div>
            <div class="btn3" @click="submit" v-if="list.length-1 == index">提交</div>

          </div>
        </div>

      </div>
    </div>
    <!-- 测试结果 -->
    <div class="opdiv opdivResult"   v-else>
      <!-- <div  class="testResult">恭喜，您的测试结果是: <span style="color: #30cab2;">{{ correspondingResults}}</span></div> -->
      <div style="font-size: 16px;font-weight: 800;padding-left: 15px;">专业分析</div>
      <div>
        <div v-for="(item, index) in hldProfessionList" :key="index" style="border-bottom: 2px solid #ccc;">
          <div class="ql-container ql-snow">
            <div class="ql-editor" v-html="item.correspondingResults">
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 16px;font-weight: 800;margin-top: 10px;padding-left: 15px;">职业建议</div>
      <div v-for="(item,index) in hldZyList" :key="index" class="hldItem">
        {{ item.correspondingResults }}
      </div>
    </div>
  </div>
</template>

<script>
import { queryTest1,queryTest1Anwser } from '@/api/homeIndex.js'

export default {
  name: 'HLD',
  mixins: [],
  components: {},
  props: [],
  data () {
    return {
       list: [],
      showIndex: 0,
      isShow: true,
      hldZyList:[],
correspondingResults:'',
hldProfessionList:[],
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
      this.getList()

  },
  methods: {
        ophanclick(item, index, questionItem) {
      item.forEach(aaa => {
        aaa.clicked = false
      })
      questionItem.clicked = !questionItem.clicked
      setTimeout(() => {
        this.changeType(item, index, 'next')

      }, 100);
    },
    //提交结果
    submit() {
      let answerList = []
      this.list.forEach(item => {
        item.forEach(questionItem => {
          if (questionItem.clicked) {
            answerList.push(questionItem.correspondingResults)
          }
        })
      })
      console.log(answerList)
      let ANum = answerList.filter(item => item == 'A').length;
      let RNum = answerList.filter(item => item == 'R').length;
      let INum = answerList.filter(item => item == 'I').length;
      let CNum = answerList.filter(item => item == 'C').length;
      let SNum = answerList.filter(item => item == 'S').length;
      let ENum = answerList.filter(item => item == 'E').length;
      // 将各种结果出现的 次数 和 对应的测试结果 组合成一个数组，然后根据 num 进行从大到小排序，截取该数组的前三项。
      let arr = [{num:ANum,name:'A'},{num:RNum,name:'R'},{num:INum,name:'I'},{num:CNum,name:'C'},{num:SNum,name:'S'},{num:ENum,name:'E'}].sort((a, b) => b.num - a.num)
      let firstArr = arr[0]
      let secondArr = arr[1]
      let thirdArr = arr[2]
      let correspondingResults = ''
      if(secondArr.num == thirdArr.num){
           //如果第二项和第三项出现的个数一样，那么结果是：1.第一项的name+第二项的name+第三项的name
          //                                           2.第一项的name+第三项的name+第二项的name
          correspondingResults = firstArr.name + secondArr.name + thirdArr.name + '|' + firstArr.name +thirdArr.name + secondArr.name
      } else if(firstArr.num  == secondArr.num ){
        //如果第一项和第二项出现的个数一样，那么结果是：1.第一项的name+第二项的name+第三项的name
          //                                        2.第二项的name+第一项的name+第三项的name
          correspondingResults = firstArr.name + secondArr.name + thirdArr.name + '|' + secondArr.name + firstArr.name +thirdArr.name
      }else if(firstArr.num  == secondArr.num == firstArr.num  == thirdArr.num){
          // 如果三项出现的次数相同，则测试无效。
          correspondingResults = ''
      }else{
          correspondingResults = firstArr.name + secondArr.name + thirdArr.name
      }
      console.log(correspondingResults)
      correspondingResults = 'RSE'
      queryTest1Anwser({ artType:correspondingResults }).then(res => {
         // 判断res.data是否包含list这个字段，包含的话，说明测试结果有效
         if(res.data.hasOwnProperty('list')){
              this.hldZyList = res.data.list
          }else{
               this.hldZyList = res.data.listTwo
          }
          this.isShow = false
          this.correspondingResults = correspondingResults
            this.hldProfessionList = res.data.listOne

      })

    },
    // 上一题 下一题
    changeType(item, index, type) {
      if (type == 'prev') {   //上一题
        if (index == 0) return   //如果是第一题，则return
        this.showIndex = index - 1
      } else {    //下一题
        if (!item.some(questionItem => questionItem.clicked)) {
          this.$message.warning('请选择一个选项')
          return
        }
        if (index == this.list.length - 1) return    //如果是最后一题，则return
        this.showIndex += 1
      }
    },
    getList() {

              queryTest1().then(res => {
                res.rows.forEach(item => {
                  item.forEach(questionItem => {
                    questionItem.clicked = false
                  })
                })
                this.list = res.rows
              })

    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
 .hldItem {
   margin-top: 10px;
   padding-left: 15px;
   font-size: 12px;
   border-bottom: 2px solid #ccc;
   padding-bottom: 10px;
 }
</style>
